<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

 <template>
	<div class="sign-in bg-gray-3">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="padding-top: 150px;">

			<a-col :span="24" :sm="24" :md="20" :lg="18" :xl="14" class="col-form">
				
				<a-card :bordered="false" class="card-info px-5 mx-0 pt-20">
					<a-row :gutter="[24,24]">
						<a-col :span="24">
							<a-row class="mt-0 pt-2 mb-20">

								<a-col :span="12" class="text-left pl-10">
									<a-button id="btnBordered" type="text" class="primary" v-if="current > 0" @click="previousScreen"><a-icon type="arrow-left" theme="outlined" /> &nbsp;&nbsp;  Back </a-button>
								</a-col>

								<a-col :span="12" class="text-right pr-10">
									<!-- <a-button id="btnBordered" type="text" class="primary" @click="skipScreen"> Skip <a-icon type="arrow-right" theme="outlined" /> &nbsp;&nbsp; </a-button> -->
								</a-col>
							</a-row>
							<!-- <a-steps :current="current">
								<a-step v-for="item in steps" :key="item.title" :title="item.title" />
							</a-steps> -->
							<a-row class="mt-0 pt-2">
								<a-col :span="24" class="px-10">
									<a-progress strokeColor="#734EBF" trailColor="success" :percent="Math.floor(((current + 1) / 11) * 100)" />
								</a-col>
							</a-row>
							<div class="steps-content">
								<!-- {{ steps[current].content }} -->
								<template>
									<a-row ty :gutter="[24,24]">
										
										<a-col :span="24" :md="24" :lg="24">

											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 20px;" v-if="current==0">
												<a-col :span="24" :md="22" :lg="22" :xl="{span: 22, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Which type of subjects do you teach?</strong>
															</label>
														</a-col>
													</a-row>

													<a-alert
														v-if="errorLearning.show"
														:message="errorLearning.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>

													<a-row type="flex" :gutter="[24, 24]" justify="space-around" align="top" class="mt-20 mb-0 pb-0">
														<a-col :span="24" :md="10" :lg="10" :xl="{span: 10, offset: 0}" class="">

															<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
																<a-col :span="24" class="my-0 py-0">
																	<a-card hoverable :bordered="true" class="card-info text-center mb-0 pb-0" :style="learning.isAcademic ? 'background-color: #734EBF; border-radius: 10px' : 'background-color: white; border-radius: 10px'" @click="handleLearningSelection(true)">
																		<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
																			<a-col :span="8" class="my-0 py-0">
																				<h4 style="font-size: 40px;">🧑🏽‍🎓</h4>
																			</a-col>
																			<a-col :span="16" class="my-0 py-0 text-left">
																				<h6 :style="learning.isAcademic ? ' color: #fff !important' : 'color: #141414'">Academic Subjects</h6>
																				<p class="description pt-0 mt-0">Eg: Mathematics</p>
																			</a-col>
																		</a-row>
																		
																		
																	</a-card>
																</a-col>
															</a-row>

															<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
																<a-col :span="24" class="my-0 py-0">
																	<a-card hoverable :bordered="true" class="card-info text-center mb-0 pb-0" :style="learning.isAcademic == false ? 'background-color: #734EBF; border-radius: 10px' : 'background-color: white; border-radius: 10px'" @click="handleLearningSelection(false)">
																		<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
																			<a-col :span="8" class="my-0 py-0">
																				<h4 style="font-size: 40px;">💼</h4>
																			</a-col>
																			<a-col :span="16" class="my-0 py-0 text-left">
																				<h6 :style="learning.isAcademic == false ? ' color: #fff !important' : 'color: #141414'">Professional Subjects</h6>
																				<p class="description pt-0 mt-0">Eg: Accounting</p>
																			</a-col>
																		</a-row>
																		
																		
																	</a-card>
																</a-col>
															</a-row>
														</a-col>
													</a-row>


													<a-row class="mt-20 pt-10 px-20 mx-20 mb-10">
														<a-col :span="24" class="text-center pr-10">
															<a-button type="primary" :loading="learning.loading" v-if="learning.isAcademic != null" class="" @click="submitLearningSubjectTypes"> Next <a-icon type="arrow-right" theme="outlined" /> &nbsp;&nbsp; </a-button>
														</a-col>
													</a-row>

												</a-col>
											</a-row>

											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 20px;" v-if="current==1">
												<a-col :span="24" :md="16" :lg="16" :xl="{span: 16, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Which education system do you fit in?</strong>
															</label>
														</a-col>
													</a-row>

													<a-alert
														class="mt-20"
														v-if="errorEducationSystem.show"
														:message="errorEducationSystem.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon/>

													
														
													<a-row :gutter="[24,24]" v-if="!educationSystem.loading">
														<a-col :span="24" class="mt-20">
															<a-badge  
																v-for="option in educationSystems"
																:key="option.uuid"
																style="font-size: 14px !important; margin: 2px 3px; padding: 5px;" 
																:count="`${option.name}`" 
																@click="handleEducationSystemSelect(option.uuid)"
																:number-style="educationSystem.uuid == option.uuid ? 
																	{backgroundColor: '#734EBF !important', color: '#FFFFFF', paddingTop: '4px', height: '30px'} : 
																	{border: '1px solid #734EBF !important', color: '#734EBF', backgroundColor: '#FFFFFF !important', paddingTop: '4px', height: '30px'}"/>
														</a-col>
													</a-row>

													<div v-if="educationSystem.loading">

														<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 10vh">

															<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

																<a-spin class="text-primary" />

															</a-col>

														</a-row>
													</div>

													<a-row ty :gutter="24" class="text-center mt-20">
														<a-col :span="24">
															<a-button :loading="educationSystem.loading" v-if="educationSystem.uuid != null" @click="submitEductionSystem" type="primary" style="width: 100px !important;" block class="row login-form-button mt-10 text-white" >
																Next
															</a-button>
														</a-col>
													</a-row>
														
												</a-col>
											</a-row>


											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 50px;" v-if="current==2">
												<a-col :span="24" :md="12" :lg="12" :xl="{span: 12, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Which grade do you teach?</strong>
															</label>
														</a-col>
													</a-row>

													<a-alert
														class="mt-20"
														v-if="errorGrade.show"
														:message="errorGrade.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>

													<a-form
														class="login-form mt-20"
														:hideRequiredMark="true">

														<a-row :gutter="[24,24]">
															<a-col :span="24" :sm="24" :md="24" :lg="24">
																<a-form-item 
																	class="py-0 my-0"
																	label=""
																	placeholder="Select Education Level"
																	:colon="false">
																	<a-select placeholder="Select Education Level" 
																		v-decorator="[ 'educationLevel',
																			{ rules: [{ required: true, message: 'Please select your education level!' }] },]" @change="onSelectEducationLevel">
																		<a-select-option v-for="option in educationLevels"
																			:value="option.uuid"
																			:key="option.name">
																			{{ option.name }}
																		</a-select-option>
																	</a-select>
																</a-form-item>
															</a-col>
														</a-row>



														<a-row :gutter="[24,24]" v-if="!grade.loading">
															<a-col :span="24" class="mt-20">
																<a-badge  
																	v-for="option in grades"
																	:key="option.uuid"
																	style="font-size: 14px !important; margin: 2px 3px; padding: 5px;" 
																	:count="`${option.name}`" 
																	@click="handleGradeSelect(option.uuid)"
																	:number-style="grade.uuid == option.uuid ? 
																		{backgroundColor: '#734EBF !important', color: '#FFFFFF', paddingTop: '4px', height: '30px'} : 
																		{border: '1px solid #734EBF !important', color: '#734EBF', backgroundColor: '#FFFFFF !important', paddingTop: '4px', height: '30px'}"/>
															</a-col>
														</a-row>

														<div v-if="grade.loading">

															<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 10vh">

																<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

																	<a-spin class="text-primary" />

																</a-col>

															</a-row>
														</div>

														<a-form-item class="mt-2 text-center mt-20 pt-20">
															<a-button :loading="grade.loading" v-if="grade.uuid" type="primary" style="width: 100px !important;" block @click="submitGrades" class="login-form-button mt-10 text-white" >
																Next
															</a-button>
														</a-form-item>
														
													</a-form>
												</a-col>
											</a-row>


											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 50px;" v-if="current==3">
												<a-col :span="24" :md="12" :lg="12" :xl="{span: 12, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Select the subjects you’ll teach</strong>
															</label>
														</a-col>
													</a-row>

													<a-alert
														class="mt-20"
														v-if="errorSubject.show"
														:message="errorSubject.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>

													<a-row :gutter="[24,24]" v-if="!subject.loading">
														<a-col :span="24" class="mt-20">
															<a-badge  
																v-for="option in subjects"
																:key="option.uuid"
																style="font-size: 14px !important; margin: 2px 3px; padding: 5px;" 
																:count="`${option.name}`" 
																@click="handleSubjectSelect(option.uuid)"
																:number-style="subject.uuids != null && subject.uuids.includes(option.uuid) ? 
																	{backgroundColor: '#734EBF !important', color: '#FFFFFF', paddingTop: '4px', height: '30px'} : 
																	{border: '1px solid #734EBF !important', color: '#734EBF', backgroundColor: '#FFFFFF !important', paddingTop: '4px', height: '30px'}"/>
														</a-col>
													</a-row>

													<div v-if="subject.loading">

														<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 10vh">

															<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

																<a-spin class="text-primary" />

															</a-col>

														</a-row>
													</div>

													<a-row ty :gutter="24" class="text-center mt-20">
														<a-col :span="24">
															<a-button :loading="subject.loading" v-if="subject.uuids != null && subject.uuids.length > 0" type="primary" style="width: 100px !important;" block @click="submitSubjects" class="login-form-button mt-10 text-white" >
																Next
															</a-button>
														</a-col>
													</a-row>
													
												</a-col>
											</a-row>


											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 20px;" v-if="current==4">
												<a-col :span="24" :md="12" :lg="12" :xl="{span: 12, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Your mode of teaching</strong>
															</label>
														</a-col>
													</a-row>

													<a-alert
														v-if="errorTeachingMode.show"
														:message="errorTeachingMode.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>

													<a-row :gutter="[24,24]">
														<a-col :span="24" class="mt-20">
															<label style="font-size: 16px">How do you intend to teach?</label><br/>
															<a-badge  
																v-for="option in availabilities"
																:key="option.uuid"
																style="font-size: 14px !important; margin: 2px 3px; padding: 5px;" 
																:count="`${option.name}`" 
																@click="handleAvailabilitySelect(option.uuid)"
																:number-style="teachingMode.availability == option.uuid ? 
																	{backgroundColor: '#734EBF !important', color: '#FFFFFF', paddingTop: '4px', height: '30px'} : 
																	{border: '1px solid #734EBF !important', color: '#734EBF', backgroundColor: '#FFFFFF !important', paddingTop: '4px', height: '30px'}"/>
														</a-col>
													</a-row>

													<a-row :gutter="[24,24]">
														<a-col :span="24" class="mt-15">
															<label style="font-size: 16px">How would you like to teach your classes?</label><br/>
															<a-badge  
																v-for="option in locations"
																:key="option.uuid"
																style="font-size: 14px !important; margin: 2px 3px; padding: 5px;" 
																:count="`${option.name}`" 
																@click="handleLocationSelect(option.uuid)"
																:number-style="teachingMode.location.includes(option.uuid) ? 
																	{backgroundColor: '#734EBF !important', color: '#FFFFFF', paddingTop: '4px', height: '30px'} : 
																	{border: '1px solid #734EBF !important', color: '#734EBF', backgroundColor: '#FFFFFF !important', paddingTop: '4px', height: '30px'}"/>
														</a-col>
													</a-row>

													<a-row :gutter="[24,24]">
														<a-col :span="24" class="mt-10">
															<label style="font-size: 16px; margin-bottom: 5px">Location</label><br/>
															<vue-google-autocomplete 
																id="locationInput" 
																style="width: 100%"
																classname="mt-5" 
																placeholder="" 
																v-on:placechanged="getAddressData">
															</vue-google-autocomplete>

														</a-col>
													</a-row>


													

													<a-row class="mt-20 pt-10 px-20 mx-20 mb-10">
														<a-col :span="24" class="text-center pr-10">
															<a-button type="primary" :loading="teachingMode.loading" v-if="teachingMode.availability != null && teachingMode.location != null && teachingMode.latitude != null" class="" @click="submitTeachingMode"> Next <a-icon type="arrow-right" theme="outlined" /> &nbsp;&nbsp; </a-button>
														</a-col>
													</a-row>

												</a-col>
											</a-row>
											

											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 20px;" v-if="current==5">
												<a-col :span="24" :md="14" :lg="14" :xl="{span: 14, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>On which days of the week are you available for teaching?</strong>
															</label>
														</a-col>
													</a-row>

													<a-alert
														v-if="errorTeachingDay.show"
														:message="errorTeachingDay.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>

													<a-row :gutter="[24,24]">
														<a-col :span="24" class="mt-20">
															<a-badge  
																v-for="option in days"
																:key="option.uuid"
																style="font-size: 14px !important; margin: 2px 3px; padding: 5px;" 
																:count="`${option.name}`" 
																@click="handleDaySelect(option.uuid)"
																:number-style="teachingDay.days.includes(option.uuid) ? 
																	{backgroundColor: '#734EBF !important', color: '#FFFFFF', paddingTop: '4px', height: '30px'} : 
																	{border: '1px solid #734EBF !important', color: '#734EBF', backgroundColor: '#FFFFFF !important', paddingTop: '4px', height: '30px'}"/>
														</a-col>
													</a-row>


													<a-row class="mt-20 pt-10 px-20 mx-20 mb-10">
														<a-col :span="24" class="text-center pr-10">
															<a-button type="primary" :loading="teachingDay.loading" v-if="teachingDay.days != null && teachingDay.days.length > 0" class="" @click="submitTeachingDays"> Next <a-icon type="arrow-right" theme="outlined" /> &nbsp;&nbsp; </a-button>
														</a-col>
													</a-row>

												</a-col>
											</a-row>


											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 20px;" v-if="current==6">
												<a-col :span="24" :md="12" :lg="12" :xl="{span: 12, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Let students know more about you</strong>
															</label>
														</a-col>
													</a-row>

													<a-alert
														v-if="errorTeacherBio.show"
														:message="errorTeacherBio.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>

													<a-row :gutter="[24,24]">
														<a-col :span="24" class="mt-20">
															<a-textarea rows="5" placeholder="Add Self Introduction Here" v-model="teacherBio.bio" style="height: 80px !important;"/>
															<p class="description pt-5" style="font-size: 12px;">Introduce yourself to the students in few words</p>
														</a-col>
													</a-row>


													<a-row class="mt-20 pt-10 px-20 mx-20 mb-10">
														<a-col :span="24" class="text-center pr-10">
															<a-button type="primary" :loading="teacherBio.loading" v-if="teacherBio.bio != null && teacherBio.bio.length > 10" class="" @click="submitTeacherBio"> Next <a-icon type="arrow-right" theme="outlined" /> &nbsp;&nbsp; </a-button>
														</a-col>
													</a-row>

												</a-col>
											</a-row>

											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 50px;" v-if="current==7">
												<a-col :span="24" :md="12" :lg="12" :xl="{span: 12, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Your education background</strong>
																<p>Tell us about your highest level of education</p>
															</label>
														</a-col>
													</a-row>

													<a-alert
														class="mt-20"
														v-if="errorEducationBackground.show"
														:message="errorEducationBackground.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>

													<a-form-model
														class="login-form mt-20"
														:hideRequiredMark="true">

														<a-row :gutter="[24,24]">
															<a-col :span="24" :sm="24" :md="24" :lg="24">
																<a-form-item 
																	class="py-0 my-0"
																	label="Education Level"
																	placeholder="Select Education Level"
																	:colon="false">
																	<a-select placeholder="Select Education Level" v-model="educationBackground.levelUuid">
																		<a-select-option v-for="option in mainEducationLevels"
																			:value="option.uuid"
																			:key="option.name">
																			{{ option.name }}
																		</a-select-option>
																	</a-select>
																</a-form-item>
															</a-col>
														</a-row>


														<a-row :gutter="[24,24]">
															<a-col :span="24" :sm="24" :md="24" :lg="24">
																<a-form-item class="py-0 my-0" label="Institute" :colon="false">
																	<a-input
																		v-model="educationBackground.institute"
																		type="text" 
																		placeholder="Institute" />
																</a-form-item>
															</a-col>
														</a-row>


														<a-row :gutter="[24,24]">
															<a-col :span="24" :sm="24" :md="24" :lg="24">
																<a-form-item class="py-0 my-0" label="Program" :colon="false">
																	<a-input
																		v-model="educationBackground.program"
																		type="text" 
																		placeholder="Program" />
																</a-form-item>
															</a-col>
														</a-row>


														<a-row :gutter="[24,24]">
															<a-col :span="24" :sm="24" :md="24" :lg="24">
																<a-form-item 
																	class="py-0 my-0"
																	label="Year of Completion"
																	placeholder="Select Year of Completion"
																	:colon="false">
																	<a-select placeholder="Select Education Level" v-model="educationBackground.year">
																		<a-select-option v-for="option in years"
																			:value="option"
																			:key="option">
																			{{ option }}
																		</a-select-option>
																	</a-select>
																</a-form-item>
															</a-col>
														</a-row>

														<a-form-item class="mt-2 text-center mt-20 pt-20">
															<a-button 
																:loading="educationBackground.loading"
																v-if="educationBackground.year != null && educationBackground.program != null && educationBackground.institute != null && educationBackground.levelUuid != null" 
																type="primary" 
																style="width: 100px !important;" block 
																@click="submitEducationBackground" 
																class="login-form-button mt-10 text-white">
																Next
															</a-button>
														</a-form-item>
														
													</a-form-model>
												</a-col>
											</a-row>

											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 50px;" v-if="current==8">
												<a-col :span="24" :md="12" :lg="12" :xl="{span: 12, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Your education background</strong>
																<p>What did you score in these subjects in secondary education?</p>
															</label>
														</a-col>
													</a-row>

													<a-alert
														class="mt-20"
														v-if="errorEducationScore.show"
														:message="errorEducationScore.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>

													<a-form-model
														class="login-form mt-20"
														:hideRequiredMark="true">

														<a-row :gutter="[24,24]">
															<a-col :span="24" :sm="24" :md="24" :lg="24">
																<a-form-item 
																	class="py-0 my-0"
																	label="Mathematics"
																	placeholder="Select Score"
																	:colon="false">
																	<a-select placeholder="Select Score" v-model="educationScore.mathematics">
																		<a-select-option v-for="option in letterGrades"
																			:value="option"
																			:key="option">
																			{{ option }}
																		</a-select-option>
																	</a-select>
																</a-form-item>
															</a-col>
														</a-row>

														<a-row :gutter="[24,24]">
															<a-col :span="24" :sm="24" :md="24" :lg="24">
																<a-form-item 
																	class="py-0 my-0"
																	label="English"
																	placeholder="Select Score"
																	:colon="false">
																	<a-select placeholder="Select Score" v-model="educationScore.english">
																		<a-select-option v-for="option in letterGrades"
																			:value="option"
																			:key="option">
																			{{ option }}
																		</a-select-option>
																	</a-select>
																</a-form-item>
															</a-col>
														</a-row>

														<a-form-item class="mt-2 text-center mt-20 pt-20">
															<a-button 
																:loading="educationScore.loading"
																v-if="educationScore.mathematics != null && educationScore.english != null"
																type="primary" 
																style="width: 100px !important;" block 
																@click="submitEducationScore" 
																class="login-form-button mt-10 text-white">
																Next
															</a-button>
														</a-form-item>
														
													</a-form-model>
												</a-col>
											</a-row>

											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 50px;" v-if="current==9">
												<a-col :span="24" :md="12" :lg="12" :xl="{span: 12, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Teaching proficiency</strong>
																<p>What did you score in these subjects in secondary education?</p>
															</label>
														</a-col>
													</a-row>

													<a-alert
														class="mt-20"
														v-if="errorTeachingProficiency.show"
														:message="errorTeachingProficiency.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>

													<a-form-model
														class="login-form mt-20"
														:hideRequiredMark="true">

														<a-row :gutter="[24,24]">
															<a-col :span="24" :sm="24" :md="24" :lg="24">
																<a-form-item 
																	class="py-0 my-0"
																	label="Are you a certified teacher"
																	:colon="false">
																	<a-radio-group v-model="teachingProficiency.certified">
																		<a-radio :value="true">Yes I'm</a-radio>
																		<a-radio :value="false">No I'm not</a-radio>
																	</a-radio-group>
																</a-form-item>
															</a-col>
														</a-row>

														<a-row :gutter="[24,24]">
															<a-col :span="24" class="mt-20">
																<a-form-item 
																	class="py-0 my-0"
																	label="For how long have you been teaching?"
																	:colon="false">
																	<a-badge  
																		v-for="option in teacherExperiences"
																		:key="option.uuid"
																		style="font-size: 14px !important; margin: 2px 3px; padding: 5px;" 
																		:count="`${option.name}`" 
																		@click="handleTeacherExperienceSelect(option.uuid)"
																		:number-style="teachingProficiency.experience == option.uuid ? 
																			{backgroundColor: '#734EBF !important', color: '#FFFFFF', paddingTop: '4px', height: '30px'} : 
																			{border: '1px solid #734EBF !important', color: '#734EBF', backgroundColor: '#FFFFFF !important', paddingTop: '4px', height: '30px'}"/>
																</a-form-item>
															</a-col>
														</a-row>

														<a-form-item class="mt-2 text-center mt-20 pt-20">
															<a-button 
																:loading="teachingProficiency.loading"
																v-if="teachingProficiency.certified != null && teachingProficiency.experience != null"
																type="primary" 
																style="width: 100px !important;" block 
																@click="submitTeachingProficiency" 
																class="login-form-button mt-10 text-white">
																Next
															</a-button>
														</a-form-item>
														
													</a-form-model>
												</a-col>
											</a-row>

											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 50px;" v-if="current==10">
												<a-col :span="24" :md="12" :lg="12" :xl="{span: 12, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Let’s add your identity</strong>
															</label>
														</a-col>
													</a-row>

													<a-alert
														class="mt-20"
														v-if="errorTeacherIdentity.show"
														:message="errorTeacherIdentity.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>

													<a-form-model
														class="login-form mt-20"
														:hideRequiredMark="true">

														<a-row :gutter="[24,24]">
															<a-col :span="24" :sm="24" :md="24" :lg="24">
																<a-form-item 
																	class="py-0 my-0"
																	label="Which ID do you have?"
																	placeholder="Select ID"
																	:colon="false">
																	<a-select placeholder="Select ID" v-model="teacherIdentity.identity">
																		<a-select-option v-for="option in teacherIdentities"
																			:value="option.uuid"
																			:key="option.name">
																			{{ option.name }}
																		</a-select-option>
																	</a-select>
																</a-form-item>
															</a-col>
														</a-row>


														<a-row :gutter="[24,24]">
															<a-col :span="24" :sm="24" :md="24" :lg="24">
																<a-form-item class="py-0 my-0" label="Upload ID Picture" :colon="false">
																	<a-input
																		placeholder="Upload ID Picture"
																		type="file"
																		ref="file"
																		@change="handleFileChange"/>
																</a-form-item>
															</a-col>
														</a-row>
														
														<a-form-item class="mt-2 text-center mt-20 pt-20">
															<a-button 
																:loading="teacherIdentity.loading"
																v-if="teacherIdentity.identity != null && teacherIdentity.file != null" 
																type="primary" 
																style="width: 100px !important;" block 
																@click="submitTeacherIdentity" 
																class="login-form-button mt-10 text-white">
																Next
															</a-button>
														</a-form-item>
														
													</a-form-model>
												</a-col>
											</a-row>
		
										</a-col>
									</a-row>
								</template>
							</div>
							<div class="steps-action">
								<!-- <a-button v-if="current < steps.length - 1" type="primary" @click="next">Next</a-button>
								
								<a-button v-if="current > 0" style="margin-left: 8px" @click="prev">Previous</a-button> -->
							</div>
						</a-col>
					</a-row>

				</a-card>
			</a-col>

		</a-row>

		<a-modal v-model="skipConfirmation.showModal" :footer="null">
			
			<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
				<a-col :span="24" class="my-0 py-0">
					<h6 style="font-size: 42px;" class="text-center">✋🏽</h6>
					<p style="font-size: 16px; font-weight: 500;">
						Providing your details helps you discover classes, Tutors & study materials that perfectly 
						match your learning goals. Are you sure you want to skip this step?
					</p>
				</a-col>
			</a-row>

			<a-row :gutters="24" class="mt-20">
				<a-col :span="24" class="text-center">
					<a-button id="textBtn" type="text" class="text-primary txtBtn" @click="toHomePage">
						Skip
					</a-button>
					&nbsp;&nbsp;
					<a-button type="primary" class="text-white txtBtn" style="" @click="cancelModal">
						Stay on the page
					</a-button>
				</a-col>
			</a-row>
			
		</a-modal>
		
	</div>
</template>

<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDGJ3oxYd4-uv44Yrkse0gMVBb64A02z3U&libraries=places"></script>
<script>
	import VueCountryCode from "vue-country-code-select";
	import VueGoogleAutocomplete from "vue-google-autocomplete";
	
    import { notification } from 'ant-design-vue';

	export default ({
		components: {
			VueCountryCode,
			VueGoogleAutocomplete
		},

		data() {
			return {
	
				current: 0,
				steps: [
					{
						title: 'Preferences',
						content: 'First-content',
					},
					{
						title: 'System',
						content: 'Second-content',
					},
					{
						title: 'Grades',
						content: 'Third-content',
					},
					{
						title: 'Subjects',
						content: 'Fourth-content',
					},
					{
						title: 'Mode',
						content: 'Fifth-content',
					},
					{
						title: 'Days',
						content: 'Fifth-content',
					},
					{
						title: 'Bio',
						content: 'Fifth-content',
					},
					{
						title: 'Background',
						content: 'Fifth-content',
					},
					{
						title: 'Score',
						content: 'Fifth-content',
					},
					{
						title: 'Proficiency',
						content: 'Fifth-content',
					},
					{
						title: 'Identity',
						content: 'Fifth-content',
					},
				],


				userLocation: {},

				// years: [""],
				mainEducationLevels: [],
				letterGrades: ['A', 'B+', 'B-', 'C', 'D', 'F', 'S'],
				teacherExperiences: [
					{ uuid: 0, name: 'Less than 6 months' },
					{ uuid: 1, name: '6 months - 2 years' },
					{ uuid: 2, name: 'More than 2 years' },
				],
				teacherIdentities: [
					{ uuid: 'Nation ID (NIDA)', name: 'Nation ID (NIDA)' },
					{ uuid: "Passport", name: "Passport" },
					{ uuid: "Driver's Licence", name: "Driver's Licence" },
				],
				grades: [],
				subjects: [],
				educationLevels: [],
				educationSystems: [],
				availabilities: [
					{ uuid: true, name: 'Full Time' },
					{ uuid: false, name: 'Part Time' },
				],
				locations: [
					{ uuid: 'Online', name: 'Online' },
					{ uuid: "At student’s home", name: "At student’s home" },
					{ uuid: "At school", name: "At school" },
				],
				days: [
					{ uuid: 'Monday', name: 'Monday' },
					{ uuid: 'Tuesday', name: 'Tuesday' },
					{ uuid: 'Wednesday', name: 'Wednesday' },
					{ uuid: 'Thursday', name: 'Thursday' },
					{ uuid: 'Friday', name: 'Friday' },
					{ uuid: 'Saturday', name: 'Saturday' },
					{ uuid: 'Sunday', name: 'Sunday' },
				],
				
				learning: {
					isAcademic: null,
					loading: false
				},

				errorLearning: {
					message: null,
					show: false,
				},

				educationSystem: {
					uuid: null,
					loading: false
				},
				errorEducationSystem: {
					message: null,
					show: false,
				},


				grade: {
					levelUuid: null,
					uuid: null,
					loading: false
				},
				errorGrade: {
					message: null,
					show: false,
				},


				subject: {
					uuids: [],
					loading: false
				},
				errorSubject: {
					message: null,
					show: false,
				},

				teachingMode: {
					availability: null,
					location: [],
					latitude: null,
					longitude: null,
					address: null,
					loading: false
				},
				errorTeachingMode: {
					message: null,
					show: false,
				},

				teachingDay: {
					days: [],
					loading: false,
				},
				errorTeachingDay: {
					message: null,
					show: false,
				},

				teacherBio: {
					bio: null,
					loading: null,
				},
				errorTeacherBio: {
					message: null,
					show: false,
				},

				educationBackground: {
					institute: null,
					year: null,
					program: null,
					levelUuid: null,
					loading: null,
				},
				errorEducationBackground: {
					message: null,
					show: false,
				},


				educationScore: {
					mathematics: null,
					english: null,
					loading: null,
				},
				errorEducationScore: {
					message: null,
					show: false,
				},

				teachingProficiency: {
					experience: null,
					certified: null,
					loading: null,
				},
				errorTeachingProficiency: {
					message: null,
					show: false,
				},

				teacherIdentity: {
					identity: null,
					file: null,
					loading: null,
				},
				errorTeacherIdentity: {
					message: null,
					show: false,
				},

				skipConfirmation: {
					showModal: false
				}
				
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			// this.personalDetailsForm = this.$form.createForm(this, { name: 'personal_details' });
			this.educationSystemForm = this.$form.createForm(this, { name: 'education_system_form' });
			this.gradeForm = this.$form.createForm(this, { name: 'grade_form' });
		},
		created() {
			this.getUserLocation();
			this.getUserDetails();
			this.getMainEducationLevels();
			// this.getEducationSystems();
		},
		computed : {
			years () {
				const year = new Date().getFullYear()
				return Array.from({length: year - 1960}, (value, index) => 1960 + (index + 1)).reverse();
			}
		},
		methods: {

			nextScreen() {
				if(this.current == 10) {
					this.notify("You have successfully finish onboarding", 'success');
					this.$router.push({ path: '/home' }).then(() => { this.$router.go() })
				}else{
					this.current++;
				}
			},

			previousScreen() {
				if(this.current > 0) {
					this.current--;
				}
			},


			skipScreen() {
				this.skipConfirmation.showModal = true;
			},

			cancelModal() {
				this.skipConfirmation.showModal = false;
			},

			toHomePage() {
				this.$router.push({ path: '/home' }).then(() => { this.$router.go() })
			},


			handleLearningSelection(isAcademic) {
				this.learning.isAcademic = isAcademic;
			},

			handleClose() {
				this.error.show = false;
			},

			async getUserDetails() {
				let userDetails = JSON.parse(await localStorage.getItem('user_details'))

				this.user = userDetails
			},


			async getUserLocation() {

				let url = `${this.$BACKEND_URL}/locations/mine`;

				this.$AXIOS.get(url).then( async(response) => {

					if(response.data.data != null) {
						
						this.userLocation =  response.data.data;
					}
				
				}).catch(error => {
					
				})
				
			},


			async getEducationSystems() {

				this.educationSystem.loading = true;

				const currentPage = 1;
				const perPage = 1000;

				let url = ''

				if(this.userLocation != null && this.userLocation.countryIsoCode != null) {
					url = `${this.$BACKEND_URL}/education/systems?isAcademic=${this.learning.isAcademic}&page=${currentPage}&size=${perPage}&countryCode=${this.userLocation.countryIsoCode}`;
				}else {
					url = `${this.$BACKEND_URL}/education/systems?isAcademic=${this.learning.isAcademic}&page=${currentPage}&size=${perPage}`;
				}

				// let url = `${this.$BACKEND_URL}/education/systems?countryCode=${this.user.countryCode}&isAcademic=${this.learning.isAcademic}&page=${currentPage}&size=${perPage}`;

				// let url = `${this.$BACKEND_URL}/education/systems?isAcademic=${this.learning.isAcademic}&page=${currentPage}&size=${perPage}`;

				this.$AXIOS.get(url).then((response) => {

					this.educationSystems = response.data.data;

					this.educationSystem.loading = false;
				
				}).catch(error => {

					this.educationSystem.loading = false;
		
					this.notify('error', error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!");
				
				})
			},


			async getEducationLevels(educationSystemUuid) {

				const currentPage = 1;
				const perPage = 1000;

				let url = `${this.$BACKEND_URL}/education/levels?educationSystem=${educationSystemUuid}&page=${currentPage}&size=${perPage}`;

				this.$AXIOS.get(url).then((response) => {

					this.educationLevels = response.data.data;

				}).catch(error => {
		
					this.notify('error', error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!");
				
				})
			},


			async getMainEducationLevels(educationSystemUuid) {

				const currentPage = 1;
				const perPage = 1000;

				let url = `${this.$BACKEND_URL}/education/levels?forLevelCheck=true&page=${currentPage}&size=${perPage}`;

				this.$AXIOS.get(url).then((response) => {

					this.mainEducationLevels = response.data.data;

				}).catch(error => {
		
					this.notify('error', error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!");
				
				})
			},


			async getGrades(levelUuid) {

				this.grade.loading = true;

				const currentPage = 1;
				const perPage = 1000;

				let url = `${this.$BACKEND_URL}/education/grades?educationLevel=${levelUuid}&page=${currentPage}&size=${perPage}`;

				this.$AXIOS.get(url).then((response) => {

					this.grades = response.data.data;

					this.grade.loading = false;

				}).catch(error => {

					this.grade.loading = false;
		
					this.notify('error', error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!");
				
				})
			},


			async getSubjects() {

				this.subject.loading = true;

				const currentPage = 1;
				const perPage = 1000;

				let url = `${this.$BACKEND_URL}/education/subjects?educationLevels=${this.grade.levelUuid}&page=${currentPage}&size=${perPage}`;

				this.$AXIOS.get(url).then((response) => {

					this.subjects = response.data.data;

					this.subject.loading = false;

				}).catch(error => {

					this.subject.loading = false;
		
					this.notify('error', error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!");
				
				})
			},


			submitLearningSubjectTypes() {
				this.learning.loading = true;

				let url = `${this.$BACKEND_URL}/profiles`;

				let payload = {
					isAcademic: this.learning.isAcademic,
					uuid: this.user.uuid,
				}

				this.$AXIOS.post(url, payload).then(async(response) => {
					if (response.status >= 200 && response.status < 210) {

						this.getEducationSystems();

						this.nextScreen();
						
					}
					this.learning.loading = false;
				}).catch((err) => {
					console.log(err)
					this.learning.loading = false;
					
					if(err.response != null  && err.response.data != null && err.response.data.message && err.response.data.message.includes('exists')) {
						this.submitUpdateLearningSubjectTypes()
					}else {
						this.errorLearning.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
						this.errorLearning.show = true
					}
				});
			},


			submitUpdateLearningSubjectTypes() {
				this.learning.loading = true;

				let url = `${this.$BACKEND_URL}/profiles/${this.user.uuid}`;

				let payload = {
					isAcademic: this.learning.isAcademic
				}

				this.$AXIOS.patch(url, payload).then(async(response) => {
					if (response.status >= 200 && response.status < 210) {

						this.getEducationSystems();

						this.nextScreen();
						
					}
					this.learning.loading = false;
				}).catch((err) => {
					console.log(err)
					this.learning.loading = false;
					
					this.errorLearning.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
					this.errorLearning.show = true
				});
			},


			handleEducationSystemSelect(uuid) {
				this.educationSystem.uuid = uuid;
			},

			async submitEductionSystem() {
				this.getEducationLevels(this.educationSystem.uuid)

				this.educationSystem.loading = true;

				let url = `${this.$BACKEND_URL}/profiles`;

				this.$AXIOS.post(url, {uuid: this.user.uuid, educationSystems: [this.educationSystem.uuid]}).then(async(response) => {
					if (response.status >= 200 && response.status < 210) {

						this.getEducationSystems();

						this.nextScreen();
						
					}
					this.educationSystem.loading = false;
				}).catch((err) => {
					console.log(err)
					this.educationSystem.loading = false;

					if(err.response != null  && err.response.data != null && err.response.data.message && err.response.data.message.includes('exists')) {
						this.submitUpdateEductionSystem()
					}else {
						this.errorEducationSystem.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
						this.errorEducationSystem.show = true
					}
				});
			},


			async submitUpdateEductionSystem() {
				this.getEducationLevels(this.educationSystem.uuid)

				this.educationSystem.loading = true;

				let url = `${this.$BACKEND_URL}/profiles/${this.user.uuid}`;

				this.$AXIOS.patch(url, {uuid: this.user.uuid, educationSystems: [this.educationSystem.uuid]}).then(async(response) => {
					if (response.status >= 200 && response.status < 210) {

						this.nextScreen();
						
					}
					this.educationSystem.loading = false;
				}).catch((err) => {
					console.log(err)
					this.educationSystem.loading = false;
					
					this.errorEducationSystem.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
					this.errorEducationSystem.show = true
				});
			},

			onSelectEducationLevel(event) {
				this.grades = [];

				this.grade.uuid = null;	
				this.grade.levelUuid = event;
				
				this.getGrades(event)
			},

			handleGradeSelect(uuid) {
				this.grade.uuid = uuid;
			},

			submitGrades() {
				
				this.grade.loading = true;

				let url = `${this.$BACKEND_URL}/profiles/${this.user.uuid}`;

				let payload = {
					educationLevels: [this.grade.levelUuid],
					educationGrades: [this.grade.uuid]
				}

				this.$AXIOS.patch(url, payload).then(async(response) => {
					if (response.status >= 200 && response.status < 210) {

						this.getSubjects();

						this.nextScreen();
						
					}
					this.grade.loading = false;
				}).catch((err) => {
					console.log(err)
					this.grade.loading = false;
					
					this.errorGrade.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
					this.errorGrade.show = true
				});
			},

			handleSubjectSelect(uuid) {
				console.log(uuid)
				if(this.subject.uuids != null && this.subject.uuids.includes(uuid)) {
					this.subject.uuids.splice(this.subject.uuids.indexOf(uuid), 1);
				}else{
					this.subject.uuids.push(uuid)
				}
			},

			submitSubjects() {
				this.subject.loading = true;

				let url = `${this.$BACKEND_URL}/profiles/${this.user.uuid}`;

				let payload = {
					subjects: this.subject.uuids,
				}

				this.$AXIOS.patch(url, payload).then(async(response) => {
					if (response.status >= 200 && response.status < 210) {

						this.nextScreen();
						
					}
					this.subject.loading = false;
				}).catch((err) => {
					console.log(err)
					this.subject.loading = false;
					
					this.errorGrade.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
					this.errorGrade.show = true
				});
			},

			handleAvailabilitySelect(availability) {
				this.teachingMode.availability = availability;
			},

			handleLocationSelect(location) {
				if(this.teachingMode.location != null && this.teachingMode.location.includes(location)) {
					this.teachingMode.location.splice(this.teachingMode.location.indexOf(location), 1);
				}else{
					this.teachingMode.location.push(location)
				}
			},

			getAddressData(addressData, placeResultData, id) {
				this.teachingMode.latitude = addressData.latitude; 
				this.teachingMode.longitude = addressData.longitude;
				this.teachingMode.address = `${addressData.route}, ${addressData.locality}, ${addressData. country}`
			},

			submitTeachingMode() {
				this.teachingMode.loading = true;

				let url = `${this.$BACKEND_URL}/profiles/${this.user.uuid}`;

				let payload = {
					teachingFullTime: this.teachingMode.availability,
					teachingModes: this.teachingMode.location
				}

				this.$AXIOS.patch(url, payload).then(async(response) => {
					if (response.status >= 200 && response.status < 210) {

						this.submitTutorLocation();

						this.nextScreen();
						
					}
					this.teachingMode.loading = false;
				}).catch((err) => {
					console.log(err)
					this.teachingMode.loading = false;
					
					this.errorTeachingMode.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
					this.errorTeachingMode.show = true
				});
			},

			submitTutorLocation() {
				this.teachingMode.loading = true;

				let url = `${this.$BACKEND_URL}/users/${this.user.uuid}`;

				let payload = {
					address: this.teachingMode.address,
					latLon: [this.teachingMode.latitude, this.teachingMode.longitude]
				}

				this.$AXIOS.patch(url, payload).then(async(response) => {
					if (response.status >= 200 && response.status < 210) {

						// this.nextScreen();
						
					}
					this.teachingMode.loading = false;
				}).catch((err) => {
					console.log(err)
					this.teachingMode.loading = false;
					
					this.errorTeachingMode.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
					this.errorTeachingMode.show = true
				});
			},

			handleDaySelect(day) {
				if(this.teachingDay.days != null && this.teachingDay.days.includes(day)) {
					this.teachingDay.days.splice(this.teachingDay.days.indexOf(day), 1);
				}else{
					this.teachingDay.days.push(day)
				}
			},

			submitTeachingDays() {
				this.teachingDay.loading = true;

				let url = `${this.$BACKEND_URL}/profiles/${this.user.uuid}`;

				let payload = {
					days: this.teachingDay.days
				}

				this.$AXIOS.patch(url, payload).then(async(response) => {
					if (response.status >= 200 && response.status < 210) {

						this.nextScreen();
						
					}
					this.teachingDay.loading = false;
				}).catch((err) => {
					console.log(err)
					this.teachingDay.loading = false;
					
					this.errorTeachingDay.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
					this.errorTeachingDay.show = true
				});
			},

			handleDaySelect(day) {
				if(this.teachingDay.days != null && this.teachingDay.days.includes(day)) {
					this.teachingDay.days.splice(this.teachingDay.days.indexOf(day), 1);
				}else{
					this.teachingDay.days.push(day)
				}
			},

			submitTeacherBio() {
				this.teacherBio.loading = true;

				let url = `${this.$BACKEND_URL}/profiles/${this.user.uuid}`;

				let payload = {
					biography: this.teacherBio.bio
				}

				this.$AXIOS.patch(url, payload).then(async(response) => {
					if (response.status >= 200 && response.status < 210) {

						this.nextScreen();
						
					}
					this.teacherBio.loading = false;
				}).catch((err) => {
					console.log(err)
					this.teacherBio.loading = false;
					
					this.errorTeacherBio.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
					this.errorTeacherBio.show = true
				});
			},

			submitEducationBackground() {
				this.educationBackground.loading = true;

				let url = `${this.$BACKEND_URL}/profiles/${this.user.uuid}`;

				let payload = {
					educationAttainedFrom: this.educationBackground.institute,
					educationAttainedYear: this.educationBackground.year,
					educationLevelAttained: this.educationBackground.levelUuid,
					educationAttainedProgram: this.educationBackground.program,
				}

				this.$AXIOS.patch(url, payload).then(async(response) => {
					if (response.status >= 200 && response.status < 210) {

						this.nextScreen();
						
					}
					this.educationBackground.loading = false;
				}).catch((err) => {
					console.log(err)
					this.educationBackground.loading = false;
					
					this.errorEducationBackground.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
					this.errorEducationBackground.show = true
				});
			},


			submitEducationScore() {
				this.educationScore.loading = true;

				let url = `${this.$BACKEND_URL}/profiles/${this.user.uuid}`;

				let payload = {
					mathEngPerformance: [this.educationScore.mathematics, this.educationScore.english]
				}

				this.$AXIOS.patch(url, payload).then(async(response) => {
					if (response.status >= 200 && response.status < 210) {

						this.nextScreen();
						
					}
					this.educationScore.loading = false;
				}).catch((err) => {
					console.log(err)
					this.educationScore.loading = false;
					
					this.errorEducationScore.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
					this.errorEducationScore.show = true
				});
			},


			handleTeacherExperienceSelect(experience) {
				this.teachingProficiency.experience = experience;
			},

			submitTeachingProficiency() {
				this.teachingProficiency.loading = true;

				let url = `${this.$BACKEND_URL}/profiles/${this.user.uuid}`;

				let payload = {
					certified: this.teachingProficiency.certified,
					teachingProficiency: this.teachingProficiency.experience
				}

				this.$AXIOS.patch(url, payload).then(async(response) => {
					if (response.status >= 200 && response.status < 210) {

						this.nextScreen();
						
					}
					this.teachingProficiency.loading = false;
				}).catch((err) => {
					console.log(err)
					this.teachingProficiency.loading = false;
					
					this.errorTeachingProficiency.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
					this.errorTeachingProficiency.show = true
				});
			},

			handleFileChange(event) {
				this.teacherIdentity.file = event.target.files[0];
			},

			async submitTeacherIdentity() {
				this.nextScreen()
				// this.teacherIdentity.loading = true;

				// let url = `${this.$BACKEND_URL}/profiles/${this.user.uuid}`;

				// let token = await localStorage.getItem("user_token")

				// const config = {
				// 	headers: {
				// 		'Content-Type': 'multipart/form-data',
				// 		'Authorization': `Bearer ${token}`,
				// 	},
				// };

				// const formData = new FormData(); 

				// formData.append("identity", this.teacherIdentity.identity); 
				// formData.append("identityFile", this.teacherIdentity.file); 
				
				// this.$AXIOS.patch(url, formData, config).then(async(response) => {
				// 	if (response.status >= 200 && response.status < 210) {

				// 		this.$router.push({ path: '/home' }).then(() => { this.$router.go() })
						
				// 	}
				// 	this.teacherIdentity.loading = false;
				// }).catch((err) => {
				// 	console.log(err)
				// 	this.teacherIdentity.loading = false;
					
				// 	this.errorTeacherIdentity.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
				// 	this.errorTeacherIdentity.show = true
				// });
			},

			notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
                
            },

		},
	})

</script>

<style lang="scss" scoped>
	body {
		background-color: #ffffff;
	}
	.ant-steps-item-process .ant-steps-item-icon { background: red; }
	#signinBtn, #signinBtn:hover {
		background-color: #632163 !important;
		color: white !important;
	}
	#submitUsernameBtn, #submitUsernameBtn:hover, #submitTokenBtn, #submitTokenBtn:hover {
		background-color: #632163 !important;
		color: white !important;
	}
	#backBtn, #backBtn:hover {
		color: #632163 !important;
		border-color: #632163 !important;
		background-color: white !important;
	}

	.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: red !important;
}

.custome-step .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: red !important;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
//    background-color: #632163 !important;
   color: #ffffff;
}

.ant-steps-item-process .ant-steps-item-icon {
    border-color: #632163 !important;
    background: #632163 !important;
}

#divId {
	display: inline-block !important;
}
</style>
